/* General form container */
#printJS-form {
  padding: 16px;
  font-family: 'Roboto', 'Arial', sans-serif;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.1;
}

/* Header styles */
.header {
  margin-bottom: 32px;
}
.header h1 {
  font-size: 2.125rem;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 0;
}

.header p {
  font-size: 0.9rem;
  margin: 0;
}

/* Section Titles */
.section-title {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 8px;
}

/* Client Info */
.client-info {
  max-width: 800px;
}

.client-info h2 {
  margin-bottom: 4px;
}

.client-info ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px; 
  margin-bottom: 0px;
  padding: 0; 
  list-style: none;
}

.client-info ul li {
  width: 50%;
  margin-bottom: 4px;
}

/* List styles */
ul {
  list-style: none;
  padding: 0;
}

ul li {
  font-size: 1rem;
  margin-bottom: 4px;
}



/* Order Information */
.order-info h2 {
  margin-bottom: 8px;
}


/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 24px;
}

table th, table td {
  padding: 4px;
  text-align: left;
}

table th, table td {
  width: 33.33%;
}


table th {
  background-color: #f5f5f5;
  font-weight: 500;
  border-bottom: 2px solid #ddd;
}

table td {
  border-bottom: 1px solid #ddd;
}

/* Order Total */
.order-total table th, table td {
  padding: 4px;
}


/* Button styles */
.print-btn {
  background-color: #3f51b5;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.print-btn:hover {
  background-color: #303f9f;
}